<template lang="pug">
  .app-checkbox.noselect(
    :class="{ disabled, highlight }"
    @click="toggle"
    @mouseover="handleMouse('over')"
    @mouseout="handleMouse('out')"
  )
    slot
    .app-checkbox-icon(
      :class="{ checked: value }"
      :data-icon="dataIcon"
      :title="label"
    )
      AppIcon(v-if="value" name="tick")
      AppIcon(v-if="indeterminate && !value" :name="indeterminateIcon")
    span.noselect(
      v-if="label"
      :title="label"
    ) {{ label }}
    slot(name="post-elem")
</template>

<script>
  export default {
    props: {
      value: Boolean,
      indeterminate: Boolean,
      indeterminateIcon: {
        type: String,
        default: "dot"
      },
      label: String,
      highlightOnHover: {
        type: Boolean,
        default: true
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        highlight: false
      }
    },

    components: {
      AppIcon: () => import("@/components/elements/AppIcon")
    },

    computed: {
      dataIcon({ value, indeterminate, indeterminateIcon }) {
        if (value) return "tick"
        if (indeterminate && !value) return indeterminateIcon

        return null
      }
    },

    methods: {
      toggle(event) {
        if (this.disabled) return

        this.$emit("change", !this.value)
        this.$emit("input", !this.value)
        event.stopPropagation()
      },

      handleMouse(action) {
        this.highlightOnHover && (this.highlight = action === "over" ? true : false)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .app-checkbox
    $highlight-background: transparentize($default-purple, 0.9)

    color: darken($default-black, 5)
    cursor: pointer
    display: flex
    flex-direction: row
    padding: 5px 10px
    align-items: center

    &.nowrap
      span
        white-space: nowrap
        overflow-x: hidden
        text-overflow: ellipsis

    ::v-deep
      .svg-fillable
        fill: $default-purple

    &-icon
      min-width: 20px
      width: 20px
      min-height: 20px
      height: 20px
      border: 1px solid $default-purple
      border-radius: 2px
      text-align: center
      display: flex
      align-items: center
      justify-content: center
      margin-right: 10px

    &.highlight
      .app-checkbox-icon
        background: $highlight-background

    &.highlight-checked
      .app-checkbox-icon
        &.checked
          background: $highlight-background

    &.disabled
      cursor: not-allowed
      > div, > span
        opacity: 0.4
</style>
